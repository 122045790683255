import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('authNSG') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('authNSG')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  // Add Cart
  async AddCart (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}cart/add_cart`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Update Cart
  async UpdateCart (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}cart/update_cart`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Detail Cart
  async DetailCart (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}cart/detail_cart`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Get Cart
  async GetCart (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}cart/get_cart`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Create Order
  async CreateCart (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}order/create_order`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Delete All Product
  async DeleteAllProduct (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}cart/delete_product_in_cart`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Get qrcode
  async GetQRCode (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}payment/get_qrcode`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Get credit
  async GetCredit (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}payment/get_credit`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Check Payment Success
  async CheckPaymentSuccess (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}payment/ck_qrcode_status_success`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Get Coupon
  async GetCoupon () {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/promotion_user`, '', auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Get Point
  async GetPoint (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/point_user`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Get Voucher
  async GetVoucher () {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/voucher_user`, '', auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // GetInvoiceByID
  async GetInvoiceById (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}invoice/taxInvoiceById`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // GetInvoiveUser
  async GetInvoiceUser (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}invoice/taxInvoiceByUser`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // InvoiceInsert
  async InvoiveInsert (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}invoice/insert`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // InvoiceUpdate
  async InvoiveUpdate (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}invoice/update`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // check Stock before create order
  async CheckStockBeforeCreateOrder (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}order/check_stock_before_create_order`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // check stock before payment
  async CheckStockBeforePayment (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}order/check_stock_before_payment`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CheckStockBeforeGetCart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}cart/check_before_get_cart`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CollectPromotionCode (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/collect_promotion_code`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SentOrdertocart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}order/sent_order_to_cart`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async IconDetailCart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}cart/icon_detail_cart`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SentRequestCancelOrder (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}request_cancel/sent_request_cancel`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CancelRequestToCancelOrder (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}request_cancel/cancel_request`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ReturnOrderCancelSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}order/return_order_cancel_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailRequest (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}request_cancel/detail_request`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SelectPromotionCart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}ngs/select_promotion_cart`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
