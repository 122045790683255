import AxiosCart from './axios_cart_api'

const NSGModuleCart = {
  state: {
    // Add Cart
    stateAddCart: [],
    // Update Cart
    stateUpdateCart: [],
    // Detail Cart
    stateDetailCart: [],
    // Get Cart
    stateGetCart: [],
    // Create Order
    stateCreateCart: [],
    // Delete All Product
    stateDeleteAllProduct: [],
    // Get qrcode
    stateGetQRCode: [],
    // Get credit
    stateGetCredit: [],
    // Check Payment Success
    statePaymentSuccess: [],
    // Get Coupon
    stateGetCoupon: [],
    // Get Point
    stateGetPoint: [],
    // Get Voucher
    stateGetVoucher: [],
    // GetInvoiceByID
    stateGetInvoiceById: [],
    // GetInvoiveUser
    stateGetInvoiceUser: [],
    // InvoiceInsert
    stateInvoiveInsert: [],
    // InvoiceUpdate
    stateInvoiveUpdate: [],
    // check Stock before create order
    stateCheckStockBeforeCreateOrder: [],
    // check stock before payment
    stateCheckStockBeforePayment: [],
    stateCheckStockBeforeGetCart: [],
    stateCollectPromotionCode: [],
    stateSentOrdertocart: [],
    // Icon Detail Cart
    stateIconDetailCart: [],
    // Sent Request Cancel Order
    stateSentRequestCancelOrder: [],
    // Cancel Request To Cancel Order
    stateCancelRequestToCancelOrder: [],
    // ReturnOrderCancelSeller
    stateReturnOrderCancelSeller: [],
    // DetailRequest
    stateDetailRequest: [],
    stateSelectPromotionCart: []
  },
  mutations: {
    // Add Cart
    mutationsAddCart (state, data) {
      state.stateAddCart = data
    },
    // Update Cart
    mutationsUpdateCart (state, data) {
      state.stateUpdateCart = data
    },
    // Detail Cart
    mutationsDetailCart (state, data) {
      state.stateDetailCart = data
    },
    // Get Cart
    mutationsGetCart (state, data) {
      state.stateGetCart = data
    },
    // Create Order
    mutationsCreateCart (state, data) {
      state.stateCreateCart = data
    },
    // Delete All Product
    mutationsDeleteAllProduct (state, data) {
      state.stateDeleteAllProduct = data
    },
    // Get qrcode
    mutationsGetQRCode (state, data) {
      state.stateGetQRCode = data
    },
    // Get credit
    mutationsGetCredit (state, data) {
      state.stateGetCredit = data
    },
    // Check Payment Success
    mutationsPaymentSuccess (state, data) {
      state.statePaymentSuccess = data
    },
    // Get Coupon
    mutationsGetCoupon (state, data) {
      state.stateGetCoupon = data
    },
    // Get Point
    mutationsGetPoint (state, data) {
      state.stateGetPoint = data
    },
    // Get Voucher
    mutationsGetVoucher (state, data) {
      state.stateGetVoucher = data
    },
    // GetInvoiceByID
    mutationsGetInvoiceById (state, data) {
      state.stateGetInvoiceById = data
    },
    // GetInvoiveUser
    mutationsGetInvoiceUser (state, data) {
      state.stateGetInvoiceUser = data
    },
    // InvoiceInsert
    mutationsInvoiveInsert (state, data) {
      state.stateInvoiveInsert = data
    },
    // InvoiceUpdate
    mutationsInvoiveUpdate (state, data) {
      state.stateInvoiveUpdate = data
    },
    // check Stock before create order
    mutationsCheckStockBeforeCreateOrder (state, data) {
      state.stateCheckStockBeforeCreateOrder = data
    },
    // check stock before payment
    mutationsCheckStockBeforePayment (state, data) {
      state.stateCheckStockBeforePayment = data
    },
    mutationsCheckStockBeforeGetCart (state, data) {
      state.stateCheckStockBeforeGetCart = data
    },
    mutationsCollectPromotionCode (state, data) {
      state.stateCollectPromotionCode = data
    },
    mutationsSentOrdertocart (state, data) {
      state.stateSentOrdertocart = data
    },
    // Icon Detail Cart
    mutationsIconDetailCart (state, data) {
      state.stateIconDetailCart = data
    },
    // Sent Request Cancel Order
    mutationsSentRequestCancelOrder (state, data) {
      state.stateSentRequestCancelOrder = data
    },
    // Cancel Request To Cancel Order
    mutationsCancelRequestToCancelOrder (state, data) {
      state.stateCancelRequestToCancelOrder = data
    },
    mutationsReturnOrderCancelSeller (state, data) {
      state.stateReturnOrderCancelSeller = data
    },
    // DetailRequest
    mutationsDetailRequest (state, data) {
      state.stateDetailRequest = data
    },
    mutationsSelectPromotionCart (state, data) {
      state.stateSelectPromotionCart = data
    }
  },
  actions: {
    // Add Cart
    async actionsAddCart (context, access) {
      const response = await AxiosCart.AddCart(access)
      await context.commit('mutationsAddCart', response)
    },
    // Update Cart
    async actionsUpdateCart (context, access) {
      const response = await AxiosCart.UpdateCart(access)
      await context.commit('mutationsUpdateCart', response)
    },
    // Detail Cart
    async actionsDetailCart (context, access) {
      const response = await AxiosCart.DetailCart(access)
      await context.commit('mutationsDetailCart', response)
    },
    // Get Cart
    async actionsGetCart (context, access) {
      const response = await AxiosCart.GetCart(access)
      await context.commit('mutationsGetCart', response)
    },
    // Create Order
    async actionsCreateCart (context, access) {
      const response = await AxiosCart.CreateCart(access)
      await context.commit('mutationsCreateCart', response)
    },
    // Delete All Product
    async actionsDeleteAllProduct (context, access) {
      const response = await AxiosCart.DeleteAllProduct(access)
      await context.commit('mutationsDeleteAllProduct', response)
    },
    // Get qrcode
    async actionsGetQRCode (context, access) {
      const response = await AxiosCart.GetQRCode(access)
      await context.commit('mutationsGetQRCode', response)
    },
    // Get credit
    async actionsGetCredit (context, access) {
      const response = await AxiosCart.GetCredit(access)
      await context.commit('mutationsGetCredit', response)
    },
    // Check Payment Success
    async actionsCheckPaymentSuccess (context, access) {
      const response = await AxiosCart.CheckPaymentSuccess(access)
      await context.commit('mutationsPaymentSuccess', response)
    },
    // Get Coupon
    async actionsGetCoupon (context) {
      const response = await AxiosCart.GetCoupon()
      await context.commit('mutationsGetCoupon', response)
    },
    // Get Point
    async actionsGetPoint (context, access) {
      const response = await AxiosCart.GetPoint(access)
      await context.commit('mutationsGetPoint', response)
    },
    // Get Voucher
    async actionsGetVoucher (context) {
      const response = await AxiosCart.GetVoucher()
      await context.commit('mutationsGetVoucher', response)
    },
    // GetInvoiceByID
    async actionsGetInvoiceById (context, data) {
      const response = await AxiosCart.GetInvoiceById(data)
      await context.commit('mutationsGetInvoiceById', response)
    },
    // GetInvoiveUser
    async actionsGetInvoiceUser (context, data) {
      const response = await AxiosCart.GetInvoiceUser(data)
      await context.commit('mutationsGetInvoiceUser', response)
    },
    // InvoiceInsert
    async actionsInvoiveInsert (context, data) {
      const response = await AxiosCart.InvoiveInsert(data)
      await context.commit('mutationsInvoiveInsert', response)
    },
    // InvoiceUpdate
    async actionsInvoiveUpdate (context, data) {
      const response = await AxiosCart.InvoiveUpdate(data)
      await context.commit('mutationsInvoiveUpdate', response)
    },
    // check Stock before create order
    async actionsCheckStockBeforeCreateOrder (context, data) {
      const response = await AxiosCart.CheckStockBeforeCreateOrder(data)
      await context.commit('mutationsCheckStockBeforeCreateOrder', response)
    },
    // check stock before payment
    async actionsCheckStockBeforePayment (context, data) {
      const response = await AxiosCart.CheckStockBeforePayment(data)
      await context.commit('mutationsCheckStockBeforePayment', response)
    },
    async actionsCheckStockBeforeGetCart (context, data) {
      const response = await AxiosCart.CheckStockBeforeGetCart(data)
      await context.commit('mutationsCheckStockBeforeGetCart', response)
    },
    async actionsCollectPromotionCode (context, data) {
      const response = await AxiosCart.CollectPromotionCode(data)
      await context.commit('mutationsCollectPromotionCode', response)
    },
    async actionsSentOrdertoccart (context, access) {
      const response = await AxiosCart.SentOrdertocart(access)
      await context.commit('mutationsSentOrdertocart', response)
    },
    async actionsIconDetailCart (context, access) {
      const response = await AxiosCart.IconDetailCart(access)
      await context.commit('mutationsIconDetailCart', response)
    },
    // Sent Request Cancel Order
    async actionsSentRequestCancelOrder (context, access) {
      const response = await AxiosCart.SentRequestCancelOrder(access)
      await context.commit('mutationsSentRequestCancelOrder', response)
    },
    // Cancel Request To Cancel Order
    async actionsCancelRequestToCancelOrder (context, access) {
      const response = await AxiosCart.CancelRequestToCancelOrder(access)
      await context.commit('mutationsCancelRequestToCancelOrder', response)
    },
    // ReturnOrderCancelSeller
    async actionsReturnOrderCancelSeller (context, access) {
      const response = await AxiosCart.ReturnOrderCancelSeller(access)
      await context.commit('mutationsReturnOrderCancelSeller', response)
    },
    // DetailRequest
    async actionsDetailRequest (context, access) {
      const response = await AxiosCart.DetailRequest(access)
      await context.commit('mutationsDetailRequest', response)
    },
    // DetailRequest
    async actionsSelectPromotionCart (context, access) {
      const response = await AxiosCart.SelectPromotionCart(access)
      await context.commit('mutationsSelectPromotionCart', response)
    }
  }
}

export default NSGModuleCart
